import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Hero from '../../components/Hero';

// import styles from './about-page.module.css';

const AboutPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					hero: {
						image: { childImageSharp: { fluid: hero } },
						alignment
					}
				},
				html: body
			}
		}
	} = data;

	return (
		<Layout>
			{	hero
				? <Hero alignment={ alignment } fluid={ hero } />
				: null
			}
			{ body
				? <section>
					<div className="wrapper">
						<div className="body" dangerouslySetInnerHTML={ { __html: body } } />
					</div>
				</section>
				: null
			}
		</Layout>
	);
};

AboutPage.propTypes = { data: PropTypes.object };

export default AboutPage;

export const query = graphql`
	query AboutPage {
		file(relativePath: {eq: "pages/about.md"}) {
			childMarkdownRemark {
				frontmatter {
					hero {
						image {
							childImageSharp {
								fluid(maxWidth: 3000, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alignment
					}
				}
				html
			}
		}
	}
`;
